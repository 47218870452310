import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticImaginaryNumbers() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];
    let secondNumber = nums[Math.floor(Math.random()*nums.length)];
    let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
    let fourthNumber = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(secondNumber === firstNumber) {
      secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    let result6 = - Math.pow(firstNumber,2);
    let result7 = - Math.pow(secondNumber,2);
    let result8 = firstNumber+secondNumber;
    let result9 = firstNumber-secondNumber;
    let result10 = firstNumber*secondNumber;
    let result11a = thirdNumber*fourthNumber - firstNumber*secondNumber;
    let result11b = thirdNumber*secondNumber + firstNumber*fourthNumber;
    let result12a = thirdNumber*fourthNumber - firstNumber*secondNumber;
    let result12b = thirdNumber*secondNumber + firstNumber*fourthNumber;
    let result13a = firstNumber*fourthNumber - thirdNumber*secondNumber;
    let result13b = firstNumber*secondNumber + thirdNumber*fourthNumber;
    let result14a = -(thirdNumber*fourthNumber + firstNumber*secondNumber);
    let result14b = firstNumber*fourthNumber - thirdNumber*secondNumber;
    let result14c = Math.pow(fourthNumber,2) + Math.pow(secondNumber,2);
    let result15a = -(firstNumber*fourthNumber - secondNumber*thirdNumber);
    let result15b = -(thirdNumber*fourthNumber + firstNumber*secondNumber);
    let result15c = Math.pow(secondNumber,2) + Math.pow(fourthNumber,2);

    generatedProblem.push({
      key: "1-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$1$`,
      format: <span>{`$i^{0}$`}</span>
    });

    generatedProblem.push({
      key: "2-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$i$`,
      format: <span>{`$i^{1}$`}</span>
    });

    generatedProblem.push({
      key: "3-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$-1$`,
      format: <span>{`$i^{2}$`}</span>
    });

    generatedProblem.push({
      key: "4-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$-i$`,
      format: <span>{`$i^{3}$`}</span>
    });

    generatedProblem.push({
      key: "5-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$1$`,
      format: <span>{`$i^{4}$`}</span>
    });

    generatedProblem.push({
      key: "6-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$ \\pm ${firstNumber} i$`,
      format: <span>{`$\\sqrt{${result6}}$`}</span>
    });

    generatedProblem.push({
      key: "7-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$ \\pm ${secondNumber} i$`,
      format: <span>{`$\\sqrt{${result7}}$`}</span>
    });

    generatedProblem.push({
      key: "8-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$ ${result8} i$`,
      format: <span>{`$${firstNumber} i + ${secondNumber} i$`}</span>
    });

    generatedProblem.push({
      key: "9-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$ ${result9} i$`,
      format: <span>{`$${firstNumber} i - ${secondNumber} i$`}</span>
    });

    generatedProblem.push({
      key: "10-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$ - ${result10}$`,
      format: <span>{`$${firstNumber} i \\cdot ${secondNumber} i$`}</span>
    });

    generatedProblem.push({
      key: "11-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      thirdNumber: thirdNumber,
      fourthNumber: fourthNumber,
      result: `$${result11a} + ${result11b} i$`,
      format: <span>{`$(${thirdNumber} + ${firstNumber} i)(${fourthNumber} + ${secondNumber} i)$`}</span>
    });

    generatedProblem.push({
      key: "12-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      thirdNumber: thirdNumber,
      fourthNumber: fourthNumber,
      result: `$${result12a} - ${result12b} i$`,
      format: <span>{`$(${thirdNumber} - ${firstNumber} i)(${fourthNumber} - ${secondNumber} i)$`}</span>
    });

    generatedProblem.push({
      key: "13-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      thirdNumber: thirdNumber,
      fourthNumber: fourthNumber,
      result: `$${result13a} - ${result13b} i$`,
      format: <span>{`$(${firstNumber} - ${thirdNumber} i)(${fourthNumber} - ${secondNumber} i)$`}</span>
    });

    let result14aSign = result14a < 0 ? "-" : "+"
    result14a = Math.abs(result14a)
    generatedProblem.push({
      key: "14-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      thirdNumber: thirdNumber,
      fourthNumber: fourthNumber,
      result: `$${displayFraction(result14b, result14c)} ${result14aSign} ${displayFraction(result14a, result14c)} i$`,
      format: <span>{`$\\dfrac{${firstNumber} - ${thirdNumber} i}{${fourthNumber} + ${secondNumber} i}$`}</span>
    });

    let result15bSign = result15b < 0 ? "-" : "+"
    result15b = Math.abs(result15b)
    generatedProblem.push({
      key: "15-aim",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      thirdNumber: thirdNumber,
      fourthNumber: fourthNumber,
      result: `$${displayFraction(result15a, result15c)} ${result15bSign} ${displayFraction(result15b, result15c)} i$`,
      format: <span>{`$\\dfrac{${firstNumber} + ${thirdNumber} i}{${secondNumber} i - ${fourthNumber}}$`}</span>
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  return (
    <div>
      

      <EquationLayout
      title={"Arithmetic Imaginary Numbers"}
      instructions={"Compute or Simplify the following."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={3}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}