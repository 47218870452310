import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet } from '../../../../utils/mathHelpers';
import graphImage from '../images/graph.png';

export default function SimplicialComplex() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const practice_problems_num = 8;
    const generatedProblem = [];

    let edge_ab = {
      name: "E_{ab}",
      edge_info: [{letter: 'b', coeff: 1}, {letter: 'a', coeff: -1}]
    };
    let edge_bc = {
      name: "E_{bc}",
      edge_info: [{letter: 'c', coeff: 1}, {letter: 'b', coeff: -1}]
    }
    let edge_ac = {
      name: "E_{ac}",
      edge_info: [{letter: 'c', coeff: 1}, {letter: 'a', coeff: -1}]
    }
    let edge_cd = {
      name: "E_{cd}",
      edge_info: [{letter: 'd', coeff: 1}, {letter: 'c', coeff: -1}]
    }

    let edges = [edge_ab, edge_bc,edge_ac, edge_cd];

    for (let step = 0; step < practice_problems_num; step++) {
      // take 3 random edges
      let selected_edges = []
      selected_edges = edges.sort(() => 0.5 - Math.random()).slice(0, 3);

      // calculate random coefficient for each edge
      let selected_edges_with_coeff = [];
      selected_edges.forEach(edge =>
        {
          selected_edges_with_coeff.push(
            Object.assign({},{...edge, random_coeff: randomNumFromSet([1, -1])})
          )
        }
      )

      // calculate answer
      let answer = {}
      selected_edges_with_coeff.forEach(edge => {
        (edge.edge_info).forEach(edge_info => {
          answer[edge_info.letter] = (answer[edge_info.letter] === undefined ? 0 : answer[edge_info.letter]) + (edge_info.coeff * edge.random_coeff)
        })
      })

      generatedProblem.push({
        key: `${step}-sc`,
        selectedEdges: selected_edges_with_coeff,
        answer: answer
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let selectedEdges = equation.selectedEdges;
    let answer = equation.answer;

    let equationFormat = "";
    let prevSignEquation = "";
    for (let x = 0; x < 3; x++) {
      prevSignEquation = "+"
      if(selectedEdges[x].random_coeff < 0) {
        prevSignEquation = "-"
      }
      equationFormat += prevSignEquation + selectedEdges[x].name + " "
    }
    equationFormat = equationFormat.charAt(0) == "+" ? equationFormat.substring(1, equationFormat.length) : equationFormat

    let answerFormat = "";
    let prevSignAnswer = "";
    Object.keys(answer).forEach(function (key) {
      prevSignAnswer = ""
      if(answer[key] != 0) {
        if([-1, 1].includes(answer[key])) {
          prevSignAnswer = answer[key] < 0 ? "-" : "+"
        }
        else {
          prevSignAnswer = answer[key] < 0 ? answer[key] : "+" + answer[key]
        }
        answerFormat += prevSignAnswer + key
      }
    })

    // Remove plus sign from beginning of string if present
    answerFormat = answerFormat.charAt(0) == "+" ? answerFormat.substring(1, answerFormat.length) : answerFormat
    answerFormat = answerFormat == "" ? 0 : answerFormat

    return {
      key: equation.key,
      equation: `$ \\partial \\left( ${equationFormat} \\right) $`,
      answer: `$${answerFormat}$`
    }
  }

  function helpText() {
    return `
      $$ E_{ab} - E_{ac} + E_{bc} $$    
      \
      $$ = (b - a) - (c - a) + (c - b) $$
      \
      $$ = b - a - c + a + c - b $$
      \
      $$ = b - b + a - a + c - c $$
      \
      $$ = 0 + 0 + 0 $$
      \
      $$ = 0 $$
      \
      The sum of the oriented edges is 0, this implies a hole/loop.
      \
      If you follow the path given you will return to the original starting point.
      \
      $$ E_{xy} = E_{x \\rightarrow y} = x \\rightarrow y = Final - Initial = y - x $$
      \
      $$ - E_{xy} = - (y - x) = -y + x = x - y = E_{yx} = y \\rightarrow x $$ `
  }

  return (
    <div>

      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <img src={graphImage} alt="Generic Graph" height={200} width={250}/>
      </div>
      
      <EquationLayout
        title={"Simplicial Complex"}
        instructions={"Compute the boundary of the Path constructed by the given Edges."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Simplicial Complex Help", helpTextFunc: helpText}}
      />
    </div>
  );
}