import React, { useState } from 'react';

import PolynomialQuadraticOneStep from './PolynomialQuadraticOneStep';
import PolynomialQuadraticTwoStep from './PolynomialQuadraticTwoStep';
import PolynomialQuadraticThreeStep from './PolynomialQuadraticThreeStep';
import PolynomialFoilA from './PolynomialFoilA';
import PolynomialFoilB from './PolynomialFoilB';
import PolynomialUnfoilA from './PolynomialUnfoilA';
import PolynomialUnfoilB from './PolynomialUnfoilB';
import PolynomialParabola from './PolynomialParabola';
import PolynomialCompleteTheSquare from './PolynomialCompleteTheSquare';
import PolynomialQuadraticFunctions from './PolynomialQuadraticFunctions';
import PolynomialCubic from './PolynomialCubic';
import PolynomialCubicFunctions from './PolynomialCubicFunctions';
import PolynomialFeatures from './PolynomialFeatures';
import PolynomialQuartic from './PolynomialQuartic';
import PolynomialFactoring from './PolynomialFactoring';
import PolynomialSimplify from './PolynomialSimplify';
import PolynomialDivision from './PolynomialDivision';
import Button from 'react-bootstrap/Button';

const QUADRATIC_ONE_STEP = "quadratic_one_step";
const QUADRATIC_TWO_STEP = "quadratic_two_step";
const QUADRATIC_THREE_STEP = "quadratic_three_step";
const FOIL_A = "foil_a";
const FOIL_B = "foil_b";
const UNFOIL_A = "unfoil_a";
const UNFOIL_B = "unfoil_b";
const PARABOLA = "parabola";
const COMPLETE_THE_SQUARE = "complete_the_square";
const QUADRATIC_FUNCTIONS = "quadratic_functions"
const CUBIC = "cubic";
const CUBIC_FUNCTIONS = "cubic_functions"
const FEATURES = "features";
const QUARTIC = "quartic";
const FACTORING = "factoring";
const SIMPLIFY = "simplify";
const DIVISION = "division";
const FUNCTION_TYPES = [QUADRATIC_ONE_STEP, QUADRATIC_TWO_STEP, QUADRATIC_THREE_STEP, FOIL_A, FOIL_B, UNFOIL_A, UNFOIL_B, PARABOLA, 
  COMPLETE_THE_SQUARE, QUADRATIC_FUNCTIONS, CUBIC, CUBIC_FUNCTIONS, FEATURES, QUARTIC, FACTORING, SIMPLIFY, DIVISION];

const functionTypeDisplayNames = {
  "quadratic_one_step": "Quadratic One Step",
  "quadratic_two_step": "Quadratic Two Step",
  "quadratic_three_step": "Quadratic Three Step",
  "foil_a": "Foil A",
  "foil_b": "Foil B",
  "unfoil_a": "Unfoil A",
  "unfoil_b": "Unfoil B",
  "parabola": "Parabola",
  "complete_the_square": "Complete The Square",
  "quadratic_functions": "Quadratic Functions",
  "cubic": "Cubic",
  "cubic_functions": "Cubic Functions",
  "features": "Features",
  "quartic": "Quartic",
  "factoring": "Factoring",
  "simplify": "Simplify",
  "division": "Division"
}

export default function Polynomial() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(QUADRATIC_ONE_STEP);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case QUADRATIC_ONE_STEP: {
        return <PolynomialQuadraticOneStep />;
      }
      case QUADRATIC_TWO_STEP: {
        return <PolynomialQuadraticTwoStep />;
      }
      case QUADRATIC_THREE_STEP: {
        return <PolynomialQuadraticThreeStep />;
      }
      case FOIL_A: {
        return <PolynomialFoilA />;
      }
      case FOIL_B: {
        return <PolynomialFoilB />;
      }
      case UNFOIL_A: {
        return <PolynomialUnfoilA />;
      }
      case UNFOIL_B: {
        return <PolynomialUnfoilB />;
      }
      case PARABOLA: {
        return <PolynomialParabola />;
      }
      case COMPLETE_THE_SQUARE: {
        return <PolynomialCompleteTheSquare />;
      }
      case QUADRATIC_FUNCTIONS: {
        return <PolynomialQuadraticFunctions />;
      }
      case CUBIC: {
        return <PolynomialCubic />;
      }
      case CUBIC_FUNCTIONS: {
        return <PolynomialCubicFunctions />;
      }
      case FEATURES: {
        return <PolynomialFeatures />;
      }
      case QUARTIC: {
        return <PolynomialQuartic />;
      }
      case FACTORING: {
        return <PolynomialFactoring />;
      }
      case SIMPLIFY: {
        return <PolynomialSimplify />;
      }
      case DIVISION: {
        return <PolynomialDivision />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}