import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function PolynomialFoilB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 5, 7, 11, -2, -3, -5, -7, -11];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.abs(firstNumber);
      let result2 = result1*thirdNumber+secondNumber;
      let result3 = secondNumber*thirdNumber;
      let result4 = -secondNumber;
      let result5 = -thirdNumber;
      let result6 = displayFraction(result4,result1);
      let sign2 = "+"
      let sign3 = "+"
      let sign2b = "+"
      let sign3b = "+"

      if(result2 < 0) {
          result2 = Math.abs(result2);
          sign2 = "-";
      }
      if(result3 < 0) {
        result3 = Math.abs(result3);
        sign3 = "-";
      }
      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign2b = "-";
      } 
      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign3b = "-";
      }        

      generatedProblem.push({
        key: `${step}-pub`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        result6: result6,
        sign3: sign3,
        sign2: sign2,
        sign3b: sign3b,
        sign2b: sign2b
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result2
    let equationToDisplay = `$ ${equation.result1} x^2 ${equation.sign2} ${equation.result2} x ${equation.sign3} ${equation.result3} $`
    if(middleTerm === 1) {
      equationToDisplay = `$ ${equation.result1} x^2 ${equation.sign2} x ${equation.sign3} ${equation.result3} $`
    }
    else if(middleTerm === 0) {
      equationToDisplay = `$ ${equation.result1} x^2 ${equation.sign3} ${equation.result3} $`
    }

    return {
      key: equation.key,
      equation: `$ (${equation.result1} x ${equation.sign2b} ${equation.secondNumber})(x ${equation.sign3b} ${equation.thirdNumber}) $`,
      answer: equationToDisplay,
    }
  }

  function helpText() {
    return `
     $$ (3x - 7)(x + 5) $$    
      \
      $$ \\textbf{F}irst = (3x) \\cdot (x) $$
      \
      $$ \\textbf{O}utside =  (3x) \\cdot (5) $$
      \
      $$ \\textbf{I}nside = (-7) \\cdot (x) $$
      \
      $$ \\textbf{L}ast = (-7) \\cdot (5) $$
      \
      $$ \\textbf{F} + \\textbf{O} + \\textbf{I} + \\textbf{L} $$
      \
      $$ (3x) \\cdot (x) + (3x) \\cdot (5) + (-7) \\cdot (x) + (-7) \\cdot (5) $$
      \
      $$ (3x^{2}) + (15x) + (-7x) + (-35) $$
      \
      $$ 3x^{2} + 15x - 7x - 35 $$
      \
      $$ 3x^{2} + (15 - 7) \\cdot x - 35 $$
      \
      $$ 3x^{2} + (8) \\cdot x - 35 $$
      \
      $$ 3x^{2} + 8x - 35 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Foil B"}
        instructions={"Distribute the 2 binomials by foiling."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />


      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Foil B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}