import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialUnfoilA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = -1*(firstNumber + secondNumber);
      let result2 = firstNumber*secondNumber;
      let sign1 = "+"
      let sign2 = "+"

      if(result1 < 0) {
          result1 = Math.abs(result1);
          sign1 = "-";
      }
      if(result2 < 0) {
        result2 = Math.abs(result2);
        sign2 = "-";
      }       

      generatedProblem.push({
        key: `${step}-qu`,
        firstNumber: firstNumber,
        exponent: 2,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2,
        sign1: sign1,
        sign2: sign2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result1
    let equationToDisplay = `$x^2 ${equation.sign1} ${equation.result1}x ${equation.sign2} ${equation.result2} = 0$`
    if(middleTerm === 1) {
      equationToDisplay = `$x^2 ${equation.sign1} x ${equation.sign2} ${equation.result2} = 0$`
    }
    else if(middleTerm === 0) {
      equationToDisplay = `$x^2 ${equation.sign2} ${equation.result2} = 0$`
    }

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$x = ${equation.firstNumber}, ${equation.secondNumber}$`,
    }
  }

  function helpText() {
    return `
      $$ x^{2} + 2x - 63 = 0 $$    
      \
      $$ a + b = 9 + (-7) = 2 $$
      \
      $$ a \\cdot b = (9) \\cdot (-7) = -63 $$
      \
      $$ (x + 9)(x - 7) = 0 $$
      \
      $$ (x + 9) = 0 $$
      \
      $$ -9 \\hspace{10mm} -9 $$
      \
      $$ x = -9 $$
      \
      $$ (x - 7) = 0 $$
      \
      $$ +7 \\hspace{10mm} +7 $$
      \
      $$ x = 7 $$
      \
      $$ x = -9, 7 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Unfoil A"}
        instructions={"Factor the polynomial, then solve the equation."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />


      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Unfoil A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}