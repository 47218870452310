import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticWordProblems() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    const frac = ["half", "one-third", "one-fourth", "one-fifth", "one-sixth", "one-seventh", "one-eighth", 
        "one-ninth", "one-tenth", "one-eleventh", "one-twelfth", "one-thirteenth", "one-fourteenth", "one-fifteenth", 
        "one-sixteenth", "one-seventeenth", "one-eighteenth", "one-ninteenth", "one-twentieth"];
    const names = ["Camilo", "Elian", "Catalina", "Keith", "Gloria", "Ricardo", "Matteo", "Sonia", "Maria"];
    const objects = ["apples", "oranges", "bananas", "coins", "boxes", "toys", "books"];
    const operations = ["more", "less", "times \\; as \\; many", "as \\; many"];
    const pairs = [nums, frac];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let n1 = nums[Math.floor(Math.random()*nums.length)];
      let n2 = nums[Math.floor(Math.random()*nums.length)];
      let obj = objects[Math.floor(Math.random()*objects.length)];
      let op = operations[Math.floor(Math.random()*operations.length)];
      let name1 = names[Math.floor(Math.random()*names.length)];
      let name2 = names[Math.floor(Math.random()*names.length)];

      let n3 = n1 + n2;
      let n4 = n1*n2;
      let a = n1;
      let b = n2;
      let c = n3;

      while(name1 == name2){
        name2 = names[Math.floor(Math.random()*names.length)];
      }

      if(op == "less"){
        a = n3;
        b = n1;
        c = n2;
      }

      if(op == "times \\; as \\; many"){
        c = n4;
      }

      if(op == "as \\; many"){
        const index = nums.indexOf(b);
        a = n4;
        b = frac[index];
        c = n1;
      }

      generatedProblem.push({
        key: `${step}-awp`,
        n1,
        n2,
        n3,
        n4,
        name1,
        name2,
        op,
        obj,
        a,
        b,
        c
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let equationToDisplay = (
      <span>
        <span>
          {`$ ${equation.name1} \\; \\text{has} \\; ${equation.a} \\; ${equation.obj}. $`}
        </span>
        <br/>
        <span>
          {`$ ${equation.name2} \\; \\text{has} \\; ${equation.b} \\; ${equation.op} \\; ${equation.obj} \\; \\text{compared to} \\; ${equation.name1}. $`}
        </span>
        <br/>
        <span>
          {`$ \\text{How many} \\; ${equation.obj} \\; \\text{does} \\; ${equation.name2} \\; \\text{have?} $`}
        </span>
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$ ${equation.c} $`
    };
  }

  function helpText() {
    return `
      $$ \\text{"more" = addition = +} $$    
      \
      $$ \\text{"less" = subtraction = -} $$
      \
      $$ \\text{"times as many" = mulitplication =} \\; \\cdot $$    
      \
      $$ \\text{"fraction as many" = division =} \\; \\div $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Arithemtic Word Problems"}
        instructions={"Compute the following."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Word Problems Help", helpTextFunc: helpText}}
      />
    </div>
  );
}