import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function PolynomialUnfoilB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 5, 7, 11, -2, -3, -5, -7, -11];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.abs(firstNumber);
      let result2 = result1*thirdNumber+secondNumber;
      let result3 = secondNumber*thirdNumber;
      let result4 = -secondNumber;
      let result5 = -thirdNumber;
      let result6 = displayFraction(result4,result1);
      let sign2 = "+"
      let sign3 = "+"

      if(result2 < 0) {
          result2 = Math.abs(result2);
          sign2 = "-";
      }
      if(result3 < 0) {
        result3 = Math.abs(result3);
        sign3 = "-";
      }       

      generatedProblem.push({
        key: `${step}-pub`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        result6: result6,
        sign3: sign3,
        sign2: sign2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result2
    let equationToDisplay = `$ ${equation.result1} x^2 ${equation.sign2} ${equation.result2} x ${equation.sign3} ${equation.result3} = 0 $`
    if(middleTerm === 1) {
      equationToDisplay = `$ ${equation.result1} x^2 ${equation.sign2} x ${equation.sign3} ${equation.result3} = 0 $`
    }
    else if(middleTerm === 0) {
      equationToDisplay = `$ ${equation.result1} x^2 ${equation.sign3} ${equation.result3} = 0 $`
    }

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$ x = ${equation.result5}, ${equation.result6} $`,
    }
  }

  function helpText() {
    return `
      $$ 3x^{2} + 8x - 35 = 0 $$    
      \
      $$ (3x + a)(x + b) $$
      \
      $$ a \\cdot b = (-7) \\cdot (5) = -35 $$
      \
      $$ (3x - 7)(x + 5) = 0 $$
      \
      $$ (3x - 7) = 0 $$
      \
      $$ +7 \\hspace{11mm} +7 $$
      \
      $$ 3x = 7 $$
      \
      $$ \\dfrac{3x = 7}{3} $$
      \
      $$ \\dfrac{3x}{3} = \\dfrac{7}{3} $$
      \
      $$ x = \\dfrac{7}{3} $$
      \
      $$ (x + 5) = 0 $$
      \
      $$ -5 \\hspace{9mm} -5 $$
      \
      $$ x = -5 $$
      \
      $$ x = \\dfrac{7}{3}, -5 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Unfoil B"}
        instructions={"Factor the polynomial, then solve the equation."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />


      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Unfoil B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}