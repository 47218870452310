import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticMultiplyingFractionsA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8];
    const practice_problems_num = 8;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber*(thirdNumber*sixthNumber+fourthNumber*fifthNumber);
      let result2 = secondNumber*fourthNumber*sixthNumber;

      generatedProblem.push({
        key: `${step}-aafa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.firstNumber}}{${equation.secondNumber}} \\cdot (\\dfrac{${equation.thirdNumber}}{${equation.fourthNumber}} + \\dfrac{${equation.fifthNumber}}{${equation.sixthNumber}})$`,
      answer: `$ \\dfrac{${equation.result1}}{${equation.result2}} $`
    }
  }

  function helpText() {
    return `$$
    
      { \\dfrac{8}{3} \\cdot \\left(\\dfrac{2}{7} + \\dfrac{6}{4} \\right)} $$
            
      \
    
      $$ {= \\dfrac{8}{3} \\cdot \\left(\\dfrac{2 \\cdot 4}{7 \\cdot 4} + \\dfrac{6 \\cdot 7}{4 \\cdot 7} \\right)} $$

      \

      $$ {= \\dfrac{8}{3} \\cdot \\left(\\dfrac{8}{28} + \\dfrac{42}{28} \\right)} $$

      \

      $$ {= \\dfrac{8}{3} \\cdot \\left(\\dfrac{8 + 42}{28} \\right)} $$

      \

      $$ {= \\dfrac{8}{3} \\cdot \\dfrac{50}{28}} $$

      \

      $$ {= \\dfrac{8 \\cdot 50}{3 \\cdot 28}} $$

      \
      
      $$ {= \\dfrac{400}{84}}
     $$`
  }

  return (
    <div>
     
      <EquationLayout
      title={"Arithmetic Associative Fractions A"}
      instructions={"Compute the following."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Associative Fractions A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}