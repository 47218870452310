import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticMultiplyingFractions() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const practice_problems_num = 8;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber*thirdNumber;
      let result2 = secondNumber*fourthNumber;

      generatedProblem.push({
        key: `${step}-amf`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.firstNumber}}{${equation.secondNumber}} \\cdot \\dfrac{${equation.thirdNumber}}{${equation.fourthNumber}}$`,
      answer: `$ \\dfrac{${equation.result1}}{${equation.result2}} $`
    }
  }

  function helpText() {
    return `
      $$ { \\dfrac{3}{7} \\cdot \\dfrac{2}{4}} $$     
      \
      $$ {= \\dfrac{3 \\cdot 2}{7 \\cdot 4}} $$
      \
      $$ {= \\dfrac{6}{28}} $$
      `
  }

  return (
    <div>
  
      <EquationLayout
        title={"Arithmetic Multiplying Fractions"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Multiplying Fractions Help", helpTextFunc: helpText}}
      />
    </div>
  );
}