import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticSquareRootsB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 5, 7];
    const nums2 = [2, 3, 4, 5, 6];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.pow(firstNumber,2) * secondNumber;

      generatedProblem.push({
        key: `${step}-asrb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ \\sqrt{${equation.result1}} $`,
      answer: `$ \\pm ${equation.firstNumber} \\sqrt{${equation.secondNumber}} $`
    }
  }

  function helpText() {
    return  `
    $$ \\sqrt{48} $$
    \
    $$ = \\sqrt{16 \\cdot 3} $$
    \
    $$ = \\sqrt{16} \\cdot \\sqrt{3} $$
    \
    $$ = \\sqrt{(\\pm 4)^{2}} \\cdot \\sqrt{3} $$
    \
    $$ = (\\pm 4) \\cdot \\sqrt{3} $$
    \
    $$ = \\pm 4 \\sqrt{3} $$
    `
  }


  return (
    <div>
      
      <EquationLayout
        title={"Arithmetic Square Roots B"}
        instructions={"Simplify the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Square Roots B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
